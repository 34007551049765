import React from "react";
import axios from "axios";
import '../../../node_modules/video-react/dist/video-react.css';
import {
  Card,
  CardHeader,
  Label,
  Row,
  Col,
  CardTitle,
  CardText,
  Table
} from "reactstrap";
import UserHeader from "../../components/Headers/UserHeader.jsx";

import SingleVideo from "../course/SingleVideo";
import FreeVideoRow from "../course/FreeVideoRow";
import cookie from 'react-cookies';
import Loader from "react-loader-spinner";
import "../loading.css";
import Rendercourse from "./Rendercourse.jsx";
class Course extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      videos: [],
      currentvideo:'',
      ptesinglevideo:{
        videoname:"Testacademia PTE",
        videodesc:"The videos covering all the modules in PTE with Grammar, are structured in depth elucidating  the crucial concepts in each section and focusing on their correct application in practice tests. Every detail is elaborated with examples and exercises towards enhanced comprehension and implementation of concepts learnt, enabling students to STUDY AT THEIR OWN PACE and accomplish their desired scores in least possible time.",
        videourl:"https://player.vimeo.com/external/436273868.m3u8?s=ec849e5e166c088200678425904c526e7a36148b"
      },
      gresinglevideo:{
        videoname:"Testacademia GRE",
        videodesc:"The videos covering all the modules in GRE with Grammar, are structured in depth elucidating the crucial concepts in each section and focusing on their correct application in practice tests. Every strategy is elaborated with examples and exercises towards enhanced comprehension and implementation of concepts learnt, enabling students to STUDY AT THEIR OWN PACE and accomplish their desired scores in least possible time. ",
        videourl:"https://player.vimeo.com/external/406537895.m3u8?s=a2cb69e14bc9614ab2b269558a82d3133971f271"
      },
      gmatsinglevideo:{
        videoname:"Testacademia GMAT",
        videodesc:"The videos covering all the modules in GMAT with Grammar, are structured in depth elucidating the crucial concepts in each section and focusing on their correct application in practice tests. Every strategy is elaborated with examples and exercises towards enhanced comprehension and implementation of concepts learnt, enabling students to STUDY AT THEIR OWN PACE and accomplish their desired scores in least possible time.",
        videourl:"https://player.vimeo.com/external/406537895.m3u8?s=a2cb69e14bc9614ab2b269558a82d3133971f271"
      },
      satsinglevideo:{
        videoname:"Testacademia SAT",
        videodesc:"The videos covering all the modules in SAT with Grammar, are structured in depth elucidating the crucial concepts in each section and focusing on their correct application in practice tests. Every strategy is elaborated with examples and exercises towards enhanced comprehension and implementation of concepts learnt, enabling students to STUDY AT THEIR OWN PACE and accomplish their desired scores in least possible time.",
        videourl:"https://player.vimeo.com/external/406537895.m3u8?s=a2cb69e14bc9614ab2b269558a82d3133971f271"
      },
      oetsinglevideo:{
        videoname:"Testacademia OET",
        videodesc:"The videos covering all the modules in OET with Grammar, are structured in depth elucidating the crucial concepts in each section and focusing on their correct application in practice tests. Every strategy is elaborated with examples and exercises towards enhanced comprehension and implementation of concepts learnt, enabling students to STUDY AT THEIR OWN PACE and accomplish their desired scores in least possible time.",
        videourl:"https://player.vimeo.com/external/406537895.m3u8?s=a2cb69e14bc9614ab2b269558a82d3133971f271"
      },
      eesinglevideo:{
        videoname:"Testacademia Expert English",
        videodesc:"The videos covering all the modules in Expert English with Grammar, are structured in depth elucidating the crucial concepts in each section and focusing on their correct application in practice tests. Every strategy is elaborated with examples and exercises towards enhanced comprehension and implementation of concepts learnt, enabling students to STUDY AT THEIR OWN PACE and accomplish their desired scores in least possible time.",
        videourl:"https://player.vimeo.com/external/406537895.m3u8?s=a2cb69e14bc9614ab2b269558a82d3133971f271"
      },
      toeflsinglevideo:{
        videoname:"Testacademia TOEFL",
        videodesc:"The videos covering all the modules in TOEFL with Grammar, are structured in depth elucidating the crucial concepts in each section and focusing on their correct application in practice tests. Every strategy is elaborated with examples and exercises towards enhanced comprehension and implementation of concepts learnt, enabling students to STUDY AT THEIR OWN PACE and accomplish their desired scores in least possible time.",
        videourl:"https://player.vimeo.com/external/406537895.m3u8?s=a2cb69e14bc9614ab2b269558a82d3133971f271"
      },
	  isLoading:true,
	  course:cookie.load('course')
  }

  this.videoList = this.videoList.bind(this);
  this.loadVideo = this.loadVideo.bind(this);
  this.getOnClickVideoId = this.getOnClickVideoId.bind(this);
  }

  componentDidMount(){
    var course = cookie.load('course')

    if (cookie.load('userid')=== undefined){
      this.props.history.push('/auth/login');
    }
    else{
      // console.log("User id is",cookie.load('userid'))
    axios.post('https://api.testacademia.com/user/'+course+'/upgrade/finduser',{userid:cookie.load('userid')})
    .then(res => {
            axios.post('https://api.testacademia.com/admin/'+course+'/package/findpackagevideos',{package:res.data.userType})
            .then(result=>{
			  this.setState({
				  videos:result.data,
				  isLoading:false
				},()=>{
                  console.log(this.state.videos)
              });
              })
            .catch(e=>console.log(e))
        })
        .catch(function(error){
            console.log(error);
        });
      }
  }

  getOnClickVideoId(videoindex){
    console.log(videoindex)
    if(this.state.course === 'pte'){
      this.setState({
        currentvideo:videoindex,
        ptesinglevideo:this.state.videos[videoindex].video
      })
    }
    if(this.state.course === 'gre'){
      this.setState({
        currentvideo:videoindex,
        gresinglevideo:this.state.videos[videoindex].video
      })
    }
    if(this.state.course === 'gmat'){
      this.setState({
        currentvideo:videoindex,
        gmatsinglevideo:this.state.videos[videoindex].video
      })
    }
    if(this.state.course === 'sat'){
      this.setState({
        currentvideo:videoindex,
        satsinglevideo:this.state.videos[videoindex].video
      })
    }
    if(this.state.course === 'oet'){
      this.setState({
        currentvideo:videoindex,
        oetsinglevideo:this.state.videos[videoindex].video
      })
    }
    if(this.state.course === 'expertenglish'){
      this.setState({
        currentvideo:videoindex,
        eesinglevideo:this.state.videos[videoindex].video
      })  
    }
    if(this.state.course === 'toefl'){
      this.setState({
        currentvideo:videoindex,
        toeflsinglevideo:this.state.videos[videoindex].video
      })  
    }
  }

  videoList(){
    const ctx = this;
	
    const videorows = [];

    if(this.state.videos){
		videorows.push(<Rendercourse 
		videos={this.state.videos}
		getOnClickVideoId={this.getOnClickVideoId}/>)
	}
	return videorows
  }

  loadVideo(){
    if(this.state.course === 'pte')
      return (<SingleVideo video={this.state.ptesinglevideo}/>);
    if(this.state.course === 'gre')
      return (<SingleVideo video={this.state.gresinglevideo}/>);
    if(this.state.course === 'gmat')
      return (<SingleVideo video={this.state.gmatsinglevideo}/>);
    if(this.state.course === 'sat')
      return (<SingleVideo video={this.state.satsinglevideo}/>);
    if(this.state.course === 'oet')
      return (<SingleVideo video={this.state.oetsinglevideo}/>);
    if(this.state.course === 'expertenglish')
      return (<SingleVideo video={this.state.eesinglevideo}/>);
  }

  render() {
    return (
      <div>
        <UserHeader />
        {/* Page content */}
        {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
          className="iconcontainer"			
				/>}
			{!this.state.isLoading &&
      <Row>
        <Col >
          {this.loadVideo()}
        </Col>
        <Col style={{marginTop:"30px", height:"550px", overflowY:"scroll"}}>
          <Col className="" >
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
			<h3 className="mb-0">{this.state.course.toUpperCase()} Course Videos</h3>
                    </div>
                    <div className="col text-right">
                    </div>
                  </Row>

                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <tbody>
                    {this.videoList()}
                  </tbody>
                </Table>
              </Card>
            </Col>
        </Col>
        </Row>}
    </div>
    );
  }
}

export default Course;
