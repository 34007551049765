import React from "react";

import { NavLink as Link } from "react-router-dom";

class FreeVideoRow extends React.Component{

    constructor(props){
        super(props);

        this.state={
            video:this.props.video
        }
        this.onPlayClicked = this.onPlayClicked.bind(this);
    }

    onPlayClicked(){
        this.props.getOnClickVideoId(this.props.index);
    }

    render(){
        return(
            <>
                {this.state.video.show &&
                <tr>
                    <th scope="row">{this.state.video.video.videoname}</th>
                    <td className="text-blue" onClick={this.onPlayClicked}>
                        <i className="ni ni-button-play text-blue" style={{marginRight:"5px"}}/>{" "}
                        Play
                    </td>
                </tr>
                }
                {!this.state.video.show &&
                <tr style={{backgroundColor:"#f3f1f1"}}>
                    <th scope="row">{this.state.video.video.videoname}</th>
                    <td style={{cursor:"pointer"}} onClick={()=>{alert("Please UPGRADE to watch the video")}}>
                        UPGRADE
                    </td>
                </tr>
                }
            </>
        )
    }
}

export default FreeVideoRow;