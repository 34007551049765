import React from "react";

// reactstrap components

// core components
import UserHeader from "../../components/Headers/UserHeader.jsx";

class Review extends React.Component {
  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        
      </>
    );
  }
}

export default Review;
