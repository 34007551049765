import React from "react";
import classnames from "classnames";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane} from "reactstrap";

class UserHeader extends React.Component {
  state = {
    tabs: 1
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  render() {
    return (
      <>
        <div className="header bg-gradient-info  pt-md-8">
          {/* <Container fluid>

            <div className="">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                
                  <NavLink
                    aria-selected={this.state.tabs === 1}
                    className={classnames("mb-sm-2 mb-md-2 ", {
                      active: this.state.tabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 1)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px", "width":"100px"}}
                  >
                    GRE
                  </NavLink>
                  <NavLink
                    aria-selected={this.state.tabs === 2}
                    className={classnames("mb-sm-2 mb-md-2", {
                      active: this.state.tabs === 2
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 2)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px", "width":"100px"}}
                  >
                    IELTS
                  </NavLink>
                  <NavLink
                    aria-selected={this.state.tabs === 3}
                    className={classnames("mb-sm-2 mb-md-2", {
                      active: this.state.tabs === 3
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 3)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px", "width":"100px"}}
                  >
                    TOEFL
                  </NavLink>
              </Nav>
            </div>
            
          </Container> */}
        </div>
            {/* <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"tabs" + this.state.tabs}>
                  <TabPane tabId="tabs1">
                    <p className="description">
                      Raw denim you probably haven't heard of them jean shorts
                      Austin. Nesciunt tofu stumptown aliqua, retro synth master
                      cleanse. Mustache cliche tempor, williamsburg carles vegan
                      helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher
                      synth.
                    </p>
                    <p className="description">
                      Raw denim you probably haven't heard of them jean shorts
                      Austin. Nesciunt tofu stumptown aliqua, retro synth master
                      cleanse.
                    </p>
                  </TabPane>
                  <TabPane tabId="tabs2">
                    <p className="description">
                      Cosby sweater eu banh mi, qui irure terry richardson ex
                      squid. Aliquip placeat salvia cillum iphone. Seitan aliquip
                      quis cardigan american apparel, butcher voluptate nisi qui.
                    </p>
                  </TabPane>
                  <TabPane tabId="tabs3">
                    <p className="description">
                      Raw denim you probably haven't heard of them jean shorts
                      Austin. Nesciunt tofu stumptown aliqua, retro synth master
                      cleanse. Mustache cliche tempor, williamsburg carles vegan
                      helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher
                      synth.
                    </p>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card> */}
      </>
    );
  }
}

export default UserHeader;
