import React from "react";
import axios from "axios";
import "./Upgrade.css";
import Packageblock from "./Packageblock.jsx"
import Loader from "react-loader-spinner";
import cookie from "react-cookies";

class Upgrade extends React.Component{
    constructor(props){
        super(props);
        this.state={
            packages:[],
            isLoading:true
        }
    }
    componentDidMount(){
        var course = cookie.load('course')
        axios.post('https://api.testacademia.com/admin/'+course+'/package/showallpack')
        .then(res=>{
            console.log(res.data)
            this.setState({
                packages: res.data,
                isLoading:false
            })
        })
        .catch(function(err){
            console.log(err)
        })
    }
renderpackageblock(){
    // console.log(packages)
    const packagerow = [];
    for(var i=0 ; i<this.state.packages.length ; i++){
        console.log(this.state.packages[i]);
        if(this.state.packages[i].packagename.split(" ")[0] === "Upgrade")
            packagerow.push(<Packageblock package={this.state.packages[i]} />)
    }
    return packagerow;
}

    render(){
        return(
            <>
            {this.state.isLoading && <Loader
                type="Puff"
                color="#00BFFF"
                height={80}
                width={80}	
                //className="iconcontainer"
              />}
            {!this.state.isLoading &&
            this.renderpackageblock()
             }
            </>
    );
  }
}
export default Upgrade;
