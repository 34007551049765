import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

// import AdminLayout from "layouts/AdminN.jsx";
import AdminLayout from "./layouts/Admin.jsx";
import AuthLayout from "./layouts/Auth.jsx";
import TestLayout from "./layouts/TestLayout.jsx";

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route path="/adminN" render={props => <userLayout {...props} />} /> */}
      <Route path="/user" render={props => <AdminLayout {...props} />} />
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Route path="/test" render={props => <TestLayout {...props} />} />
      <Route path="/user/Course/:id" render={props => <AdminLayout {...props} />}/>
      <Redirect from="/" to="/auth/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
