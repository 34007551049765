import Profile from "./views/examples/Profile.jsx";
import Register from "./views/examples/Register.jsx";
import Login from "./views/examples/Login.jsx";
import Review from "./views/examples/Review.jsx";

import Course from "./views/course/Course.jsx";
var routes = [
  {
    path: "/Course",
    name: "Course",
    icon: "ni ni-book-bookmark text-blue",
    component: Course,
    layout: "/user",
    show: "yes"
  }, 
  {
    path: "/user-profile",
    name: "Flash Cards",
    icon: "ni ni-credit-card text-red",
    component: Profile,
    layout: "/user",
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Review,
    layout: "/AdminN"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
];
export default routes;
