import React from "react";
import ReactPlayer from 'react-player';
import {
    Card,
    CardBody,
    CardTitle,
    CardText,
  } from "reactstrap";

class SingleVideo extends React.Component{

    constructor(props){
        super(props);

        this.state={
            video:this.props.video

        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.video !== this.props.video) {
            this.setState({video: this.props.video});
          }
    }

    render(){
        return(
            <>
                <Card style={{ width: "40rem", marginLeft: "40px", marginTop: "30px"}}>
                    <ReactPlayer url={this.state.video.videourl} playing='false'controls="true"/>
                    <CardBody>
                        <CardTitle>{this.state.video.videoname}</CardTitle>
                        <CardText>{this.state.video.videodesc}
                        </CardText>
                    </CardBody>
                </Card>
            </>
        )
    }
}

export default SingleVideo;