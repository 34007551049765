import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
// import { NavLink as NavLinkRRD, Link } from "react-router-dom";

import {
  Navbar,
  Container,
} from "reactstrap";
import  cookie  from "react-cookies";

class AdminNavbar extends React.Component {
  constructor(){
    super();
    this.deletecookie = this.deletecookie.bind(this);
  }
  deletecookie(){
    cookie.remove('userid',{path:"/"})
    console.log(cookie.load('userid'))
  }
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid
             className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
              {this.props.brandText}
          </Container>
          <Container style={{paddingLeft:"800px"}}>
            <Link
              to="/"
            >
            <i className="ni ni-button-power text-white" style={{paddingRight:"7px"}}  onClick={this.deletecookie} />
            <span style={{color:"white"}} onClick={this.deletecookie}>Logout</span>
            </Link>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
