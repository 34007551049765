import React from "react";
import {
    Card,
    CardHeader,
    Label,
    Row,
    Col,
    CardTitle,
    CardText,
    Table
  } from "reactstrap";
import FreeVideoRow from "./FreeVideoRow";
import cookie from 'react-cookies';


class Rendercourse extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            course : cookie.load('course'),
            videos : this.props.videos,
        }
        this.courselist= this.courselist.bind(this);
    }


courselist(){
    const readingrows = [];
    const speakingrows = [];
    const listeningrows = [];
    const writingrows = [];
    const grammarrows = [];
    const quantarthmetic = [];
	const quantalgebra = [];
	const quantgeo = [];
	const quantdata = [];
	const verbal = [];
	const verbalreading = [];
	const analytical = [];
    const sentence = [];    
	const quantmis =[];
	const essay=[];
	const basicgrammar=[];
	const advangrammar=[];
    const businessetiq=[];
    const verbalcrit = [];
    const integrated = [];
    const videorows = [];
    for(var i=0;i<this.state.videos.length;i++){
        if(this.state.videos[i]){
            if(this.state.course === "pte"||this.state.course == "toefl"){
                if(this.state.videos[i].video.subheading === "Speaking" ){
                    speakingrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                  
                else if(this.state.videos[i].video.subheading === "Writing" ){
                    writingrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
        
                else if(this.state.videos[i].video.subheading === "Reading" ){
                    readingrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
        
                else if(this.state.videos[i].video.subheading === "Listening" ){
                    listeningrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
        
                else if(this.state.videos[i].video.subheading === "Grammar" ){
                        grammarrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
            }
            else if(this.state.course === "gre"){
                if(this.state.videos[i].video.subheading === "Quant (Arithmetic)" ){
                    quantarthmetic.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Quant (Algebra)" ){
                    quantalgebra.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Quant (Geometry)" ){
                    quantgeo.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Quant (Data Analysis)" ){
                    quantdata.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Verbal" ){
                    verbal.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Verbal (Reading Comprehension)" ){
                    verbalreading.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Analytical Writing" ){
                    analytical.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
            }
            else if(this.state.course === "gmat"){
                if(this.state.videos[i].video.subheading === "Quant (Arithmetic)" ){
                    quantarthmetic.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Quant (Algebra)" ){
                    quantalgebra.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Quant (Geometry)" ){
                    quantgeo.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Quant (Data Analysis)" ){
                    quantdata.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].subheading === "Verbal" ){
                    verbal.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Verbal (Reading Comprehension)" ){
                    verbalreading.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Verbal (Sentence Correction)" ){
                    sentence.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Analytical Writing" ){
                    analytical.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Grammar" ){
                    grammarrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Verbal (Critical Reasoning)" ){
                    verbalcrit.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Integrated Reasoning" ){
                    integrated.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
            }
            else if(this.state.course === "sat"){
                if(this.state.videos[i].video.subheading === "Quant (Algebra)" ){
                    quantalgebra.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Quant (Arithmetic)" ){
                    quantarthmetic.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Quant (Data Analysis)" ){
                    quantdata.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Quant (Geometry)" ){
                    quantgeo.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
                else if(this.state.videos[i].video.subheading === "Quant (Miscellaneous Topics)" ){
                    quantmis.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Grammar" ){
                    grammarrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }        
                else if(this.state.videos[i].video.subheading === "Reading" ){
                    readingrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Writing" ){
                    writingrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Essay" ){
                    essay.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
            }
            else if(this.state.course === "oet"){               
                if(this.state.videos[i].video.subheading === "Writing" ){
                    writingrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Speaking" ){
                    speakingrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }      
                else if(this.state.videos[i].video.subheading === "Listening" ){
                    listeningrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Reading" ){
                    readingrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Grammar" ){
                        grammarrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
            }
            else if(this.state.course === "expertenglish"){               
                if(this.state.videos[i].video.subheading === "Basic Grammar" ){
                    basicgrammar.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Advanced Grammar" ){
                    advangrammar.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }      
                else if(this.state.videos[i].video.subheading === "Reading" ){
                    readingrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Writing" ){
                    writingrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Speaking" ){
                    speakingrows.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                }
                else if(this.state.videos[i].video.subheading === "Business Etiquette" ){
                    businessetiq.push(<FreeVideoRow video={this.state.videos[i]} index={i} getOnClickVideoId={this.props.getOnClickVideoId} />);
                } 
            }
        }
    }
    var courselist = this.rendercourse(speakingrows,listeningrows,writingrows,readingrows,grammarrows,
        quantalgebra,quantarthmetic,quantdata,quantgeo,verbal,
        verbalreading,sentence,analytical,   
        quantmis,essay,basicgrammar, advangrammar, businessetiq,verbalcrit,integrated)
        return courselist;
}

rendercourse(speakingrows,listeningrows,writingrows,readingrows,grammarrows,
            quantalgebra,quantarthmetic,quantdata,quantgeo,verbal,
            verbalreading,sentence,analytical,quantmis,essay,basicgrammar, advangrammar, businessetiq,verbalcrit, integrated) {
    var videorows=[];
    if (this.state.course === "pte"||this.state.course == "toefl"){
        if(speakingrows.length!==0){
            videorows.push(
                <>
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Speaking</h3>
                                </div>
                                <div className="col text-right">
                                </div>
                            </Row>
                        </CardHeader>
                        {speakingrows}
                </>
            )
        }
        if(writingrows.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Writing</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {writingrows}
                </>
            )
        }
        if(readingrows.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Reading</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {readingrows}
                </>
            )
        }
        if(listeningrows.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Listening</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {listeningrows}
                </>
            )
        }
        if(grammarrows.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Grammar</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {grammarrows}
                </>
            )
        }
        return videorows
    }
    else if(this.state.course === "gre"){
        if(quantarthmetic.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Arithmetic)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantarthmetic}
				</>
			)
		}
		if(quantalgebra.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Algebra)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantalgebra}
				</>
			)
		}
		if(quantgeo.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Geometry)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantgeo}
				</>
			)
		}
		if(quantdata.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Data Analysis)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantdata}
				</>
			)
		}
		if(verbal.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Verbal</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{verbal}
				</>
			)
		}
		if(verbalreading.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Verbal (Reading Comprehension)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{verbalreading}
				</>
			)
		}
		if(analytical.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Analytical Writing</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{analytical}
				</>
			)
        }
        return videorows;
    }
    else if(this.state.course === "gmat"){
        if(quantarthmetic.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Quant (Arithmetic)</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {quantarthmetic}
                </>
            )
        }
    if(quantalgebra.length!==0){
        videorows.push(
            <>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Quant (Algebra)</h3>
                        </div>
                        <div className="col text-right">
                        </div>
                    </Row>
                </CardHeader>
                {quantalgebra}
            </>
        )
    }
    if(quantgeo.length!==0){
        videorows.push(
            <>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Quant (Geometry)</h3>
                        </div>
                        <div className="col text-right">
                        </div>
                    </Row>
                </CardHeader>
                {quantgeo}
            </>
        )
    }
    if(quantdata.length!==0){
        videorows.push(
            <>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Quant (Data Analysis)</h3>
                        </div>
                        <div className="col text-right">
                        </div>
                    </Row>
                </CardHeader>
                {quantdata}
            </>
        )
    }
    if(verbal.length!==0){
        videorows.push(
            <>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Verbal</h3>
                        </div>
                        <div className="col text-right">
                        </div>
                    </Row>
                </CardHeader>
                {verbal}
            </>
        )
    }
    if(verbalreading.length!==0){
        videorows.push(
            <>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Verbal (Reading Comprehension)</h3>
                        </div>
                        <div className="col text-right">
                        </div>
                    </Row>
                </CardHeader>
                {verbalreading}
            </>
        )
    }
    if(verbalcrit.length!==0){
        videorows.push(
            <>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Verbal (Critical Reasoning)</h3>
                        </div>
                        <div className="col text-right">
                        </div>
                    </Row>
                </CardHeader>
                {verbalcrit}
            </>
        )
    }
    if(sentence.length!==0){
        videorows.push(
            <>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Verbal (Sentence Correction)</h3>
                        </div>
                        <div className="col text-right">
                        </div>
                    </Row>
                </CardHeader>
                {sentence}
            </>
        )
    }
    if(analytical.length!==0){
        videorows.push(
            <>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Analytical Writing</h3>
                        </div>
                        <div className="col text-right">
                        </div>
                    </Row>
                </CardHeader>
                {analytical}
            </>
        )
    }
    if(integrated.length!==0){
        videorows.push(
            <>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Integrated Reasoning</h3>
                        </div>
                        <div className="col text-right">
                        </div>
                    </Row>
                </CardHeader>
                {integrated}
            </>
        )
    }
    if(grammarrows.length!==0){
		videorows.push(
			<>
				<CardHeader className="border-0">
					<Row className="align-items-center">
						<div className="col">
							<h3 className="mb-0">Grammar</h3>
						</div>
						<div className="col text-right">
						</div>
					</Row>
				</CardHeader>
				{grammarrows}
			</>
		)
	}
	return videorows
    }
    else if(this.state.course === "sat"){
		if(quantalgebra.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Algebra)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantalgebra}
				</>
			)
        }
        if(quantarthmetic.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Arithmetic)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantarthmetic}
				</>
			)
		}
		if(quantdata.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Data Analysis)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantdata}
				</>
			)
        }
        if(quantgeo.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Geometry)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantgeo}
				</>
			)
        }
        if(quantmis.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Miscellaneous Topics)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantmis}
				</>
			)
		}
		if(grammarrows.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Grammar</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{grammarrows}
				</>
			)
		}
		if(readingrows.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Reading</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{readingrows}
				</>
			)
		}
		if(writingrows.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Writing</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{writingrows}
				</>
			)
        }
        if(essay.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Essay</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{essay}
				</>
			)
        }
        return videorows;
    }
    else if(this.state.course === "oet"){
        if(writingrows.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Writing</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {writingrows}
                </>
            )
        }
        if(speakingrows.length!==0){
            videorows.push(
                <>
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Speaking</h3>
                                </div>
                                <div className="col text-right">
                                </div>
                            </Row>
                        </CardHeader>
                        {speakingrows}
                </>
            )
        }
        if(listeningrows.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Listening</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {listeningrows}
                </>
            )
        }
        if(readingrows.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Reading</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {readingrows}
                </>
            )
        }
        if(grammarrows.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Grammar</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {grammarrows}
                </>
            )
        }
	return videorows
    }
    else if(this.state.course === "expertenglish"){
        if(basicgrammar.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Basic Grammar</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {basicgrammar}
                </>
            )
        }
        if(advangrammar.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Advanced Grammar</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {advangrammar}
                </>
            )
        }
        if(readingrows.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Reading</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {readingrows}
                </>
            )
        }
        if(writingrows.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Writing</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {writingrows}
                </>
            )
        }
        if(speakingrows.length!==0){
            videorows.push(
                <>
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Speaking</h3>
                                </div>
                                <div className="col text-right">
                                </div>
                            </Row>
                        </CardHeader>
                        {speakingrows}
                </>
            )
        }
        if(businessetiq.length!==0){
            videorows.push(
                <>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Business Etiquette</h3>
                            </div>
                            <div className="col text-right">
                            </div>
                        </Row>
                    </CardHeader>
                    {businessetiq}
                </>
            )
        }
	return videorows
    }
}

render(){
    return(
        <>
        {this.courselist()}
        </>
    )
}
}
export default Rendercourse;