import React from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import Loader from "react-loader-spinner";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { relative } from "path";

class Login extends React.Component {

  constructor(props){
    super(props);

    this.onChangeuserid = this.onChangeuserid.bind(this);
    this.onChangepassword = this.onChangepassword.bind(this);

    this.onlogin = this.onlogin.bind(this);
    this.state={
      course:"pte",
      userid: '',
      password:'',
      isLoading:false
    }
  }
  componentDidMount(){
    // cookie.save('course',this.state.course,{path:"/",maxAge:36000})
    //var coursename = "https://pte.testacademia.com"
    this.setState({
      course:window.location.hostname.split('.')[0]
    },()=>{
      cookie.save('course',this.state.course,{path:"/",maxAge:36000})
      console.log(cookie.load('course'))
    })
  }

  onChangeuserid(e){
    this.setState({
        userid: e.target.value
    });
  }

  onChangepassword(e){
    this.setState({
        password: e.target.value
    });
  }

  onlogin(e){
    this.setState({isLoading:true})
    e.preventDefault();
    const newLogin ={
      email: this.state.userid,
      password: this.state.password,
    }
    console.log(this.state)
    var course = cookie.load('course')
    axios.post('https://api.testacademia.com/user/'+course+'/auth/login', newLogin)
    .then(res => {
        console.log("database data is",res.data)
        cookie.save('userid',res.data,{path:"/",maxAge:10800})
        // cookie.save('userType',res.data.userType,{path:"/",maxAge:10800})
        // cookie.save('userData',res.data,{path:"/",maxAge:10800})
        cookie.load('userid')
        // cookie.load('userType')
        // cookie.load('userData')
        if(res.data!==''){
          this.setState({isLoading:false})
          this.props.history.push('/user/Course');
        }else{
          alert("Enter Right Email and Password");
          this.setState({isLoading:false})
          this.props.history.push('/');
        }
        })
    .catch(e=>{
      alert("Enter Right Email and Password")
      this.setState({isLoading:false})
      this.props.history.push('/');
  })

    this.setState({
        userid: '',
        password:'',
      })
    }



  render() {
    return (
      <>{this.state.isLoading && <Loader
        type="Puff"
        color="#00BFFF"
        height={80}
        width={80}
        className="iconcontainer"			
      />}
    {!this.state.isLoading &&
        <Col lg="5" md="7" style={{"position":"relative", "left":"300px"}}>
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <img src={require("../../assets/img/aspirelogo.png")} style={{"width":"200px"}}/>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" value={this.state.userid}
                          onChange={this.onChangeuserid}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" 
                        value={this.state.password}
                        onChange={this.onChangepassword}/>
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                 
                    <Link to="./Register"className="text-muted" style={{marginLeft:'100px',fontSize:'14px'}}>Create an account</Link>
                  
                </div>
                <div className="text-center">
                <Link to="user/Dashboard">
                  <Button className="my-4" color="primary" onClick={this.onlogin}>
                    Sign in
                  </Button>
                  </Link>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>}
      </>
    );
  }
}

export default Login;
